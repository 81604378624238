import React from 'react'
import CalculatorForm from '../CalculatorForm/CalculatorForm'
import defaultState from '../../data/defaultState'
import {getEditValue, getCalcValue, getStyledValue, getInputValue} from '../../Helpers/valueHelpers'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep';


class Calculator extends React.Component {
    constructor(props) {
        super(props)

        this.state = defaultState

        this.handleInput = this.handleInput.bind(this)
        this.handleDateChange = this.handleDateChange.bind(this)
        this.handleFocus = this.handleFocus.bind(this)
        this.handleBlur = this.handleBlur.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSwitch = this.handleSwitch.bind(this)
        this.handleResize = this.handleResize.bind(this)
        this.getZinsSatz = this.getZinsSatz.bind(this)
        this.prozessZins = this.prozessZins.bind(this)

        this.handles = {
            handleInput: this.handleInput,
            handleDateChange: this.handleDateChange,
            handleFocus: this.handleFocus,
            handleBlur: this.handleBlur,
            handleChange: this.handleChange,
            handleSwitch: this.handleSwitch
        }

        if (! window.matchMedia('(min-width: 960px)').matches) {
            this.state.mobile = true;
        }

        this.verzugszinsmodelle = {
            verbrauchergeschaeft: 'Verbrauchergeschäft',
            handelsgeschaeft: 'Handelsgeschäft',
            statisch: 'Statischer Zinssatz',
            dynamisch: 'Dynamischer ZInssatz'
        }

        this.calculate = this.calculate.bind(this)
    }

    componentDidMount() {
        this.calculated = 0

        window.addEventListener('resize', this.handleResize)
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    handleResize() {
        if (window.matchMedia('(min-width: 960px)').matches) {
            this.setState({
                mobile: false
            });
        }
        else {
            this.setState({
                mobile: true
            });            
        }
    }

    getZinsSatz(startDatum = null) {
        const verbrauchergeschaeftGetZinssatz = (year, month) => {
            if (year < 2000) { 
                return 4
            }
            if (year === 2000 && month < 5) { 
                return 4
            }
            if (year === 2000 && month < 9) { 
                return 8.42
            }
            if (year === 2000) { 
                return 9.26
            }
            if (year === 2001 && month < 9) { 
                return 9.26
            }
            if (year === 2001) { 
                return 8.62
            }
            if (year === 2002 && month < 7) { 
                return 7.57
            }
            if (year === 2002) { 
                return 7.47
            }
            if (year === 2003 && month < 7) { 
                return 6.97
            }
            if (year === 2003) { 
                return 6.22
            }
            if (year === 2004 && month < 7) { 
                return 6.14
            }
            if (year === 2004) { 
                return 6.13
            }
            if (year === 2005 && month < 7) { 
                return 6.21
            }
            if (year === 2005) { 
                return 6.17
            }
            if (year === 2006 && month < 7) { 
                return 6.37
            }
            if (year === 2006) { 
                return 6.95
            }
            if (year === 2007 && month < 7) { 
                return 7.70
            }
            if (year === 2007) { 
                return 8.19
            }
            if (year === 2008 && month < 7) { 
                return 8.32
            }
            if (year === 2008) { 
                return 8.19
            }
            if (year === 2009 && month < 7) { 
                return 6.62
            }
            if (year === 2009) { 
                return 5.12
            }
            if (year === 2010 && month < 7) { 
                return 5.12
            }
            if (year === 2010) { 
                return 5.12
            }
            if (year === 2011 && month < 7) { 
                return 5.12
            }
            if (year === 2011) { 
                return 5.37
            }
            if (year === 2012 && month < 7) { 
                return 5.12
            }
            if (year === 2012) { 
                return 5.12
            }
            if (year === 2013 && month < 7) { 
                return 4.87
            }
            if (year === 2013) { 
                return 4.62
            }
            if (year === 2014 && month < 7) { 
                return 4.37
            }
            if (year === 2014) { 
                return 4.27
            }
            if (year === 2015) { 
                return 4.17
            }
            if (year === 2016 && month < 7) { 
                return 4.17
            }
                    
            return 4.12
        }

        const handelsgeschaeftGetZinssatz = (year, month) => {
            if (year < 2000){ 
                return 5
            }
            if (year === 2000 && month < 5){ 
                return 5
            }
            if (year === 2000 && month < 9){ 
                return 8.42
            }
            if (year === 2000){ 
                return 9.26
            }
            if (year === 2001 && month < 9){ 
                return 9.26
            }
            if (year === 2001){ 
                return 8.62
            }

            if (startVor) {
                return getZinssatzFunctions.verbrauchergeschaeft(year, month) + 3
            }
        
            return getZinssatzFunctions.verbrauchergeschaeft(year, month) + 4
        }

        const dynamischGetZinssatz = (year, month) => {
            if (year < 1999) {
                return 0 + this.state.dynamischerZinssatz.calcValue
            }
            if (year === 1999 && month < 5) {
                return 2.5 + this.state.dynamischerZinssatz.calcValue
            }
            if (year === 1999) {
                return 1.95 + this.state.dynamischerZinssatz.calcValue
            }
            if (year === 2000 && month < 5) {
                return 2.68 + this.state.dynamischerZinssatz.calcValue
            }	
            
            return getZinssatzFunctions.verbrauchergeschaeft() - 5  + this.state.dynamischerZinssatz.calcValue
        }

        const statischGetZinssatz = (year, month) => {
            return this.state.statischerZinssatz.calcValue
        }

        const getZinssatzFunctions = {
            verbrauchergeschaeft: verbrauchergeschaeftGetZinssatz,
            handelsgeschaeft: handelsgeschaeftGetZinssatz,
            dynamisch: dynamischGetZinssatz,
            statisch: statischGetZinssatz,
        }

        if (! startDatum) {
            startDatum = this.state.startDatum.moment
        }

        const year = parseInt(startDatum.format('YYYY'))
        const month = parseInt(startDatum.format('MM'))
        const day = parseInt(startDatum.format('DD'))

        let startVor = false
        if (year < 2014 || (month < 7 && year === 2014) || (day < 29 && month === 7 && year === 2014)) {
            startVor = true
        }

        return getZinssatzFunctions[this.state.verzugzinsmodell.value](year, month)
    }

    prozessZins(startDate, letzter, days, startZins) {
        let zinsen = 0
        let tageszinsen = 0

        
        let year = parseInt(startDate.format('YYYY'))
        
        const tage = Math.round(letzter.diff(startDate, 'days')) + 1
        const tageImJahr = Math.round(moment([year, 11, 31]).diff(moment([year, 0, 1]), 'days')) + 1
        
        tageszinsen = this.state.forderung.calcValue * startZins / 100 / tageImJahr;
        
        
        zinsen += tageszinsen * tage;

        return {
            calcStartDate: startDate,
            calcEndDate: letzter,
            days: tage,
            zinsen: zinsen,
            zinsSatz: startZins
        }
    }

    calculate(fieldName = null) {
        if (this.state.forderung.calcValue <= 0) {
            //return;
        }

        let newState = {}

        let tagesanzahl = 0;
        if (moment.isMoment(this.state.startDatum.moment) && moment.isMoment(this.state.endDatum.moment)) {
            const days = this.state.endDatum.moment.diff(this.state.startDatum.moment, 'days') + 1

            tagesanzahl = {
                calcValue: days,
                styledValue: getStyledValue(days, 'number', 0)
            }
        }
        else {
            return
        }

        let calcDays = 0
        let result = false
        
        let lastDate = false
        let startDate = cloneDeep(this.state.startDatum.moment)
        let current = cloneDeep(this.state.startDatum.moment)
        let currentZins = this.getZinsSatz(current)

        let gesamtForderung = 0
        let gesamtZinsen = 0

        let tableDates = []
        let tableDays = []
        let tableZinssatz = []
        let tableZinsen = []
        let tableForderung = []

        for (let currentDay = 0; currentDay < tagesanzahl.calcValue; currentDay ++) {

            if (currentZins !== this.getZinsSatz(current) || current.format('YYYY') !== startDate.format('YYYY')) {
                lastDate = moment.unix(current.unix())
                lastDate.subtract(1, 'day')

                result = this.prozessZins(startDate, lastDate, calcDays, currentZins)

                gesamtForderung += result.zinsen
                gesamtZinsen += result.zinsen

                tableDates.push(`${result.calcStartDate.format('DD.MM.YYYY')} - ${result.calcEndDate.format('DD.MM.YYYY')}`)
                tableDays.push(result.days)
                tableZinssatz.push(result.zinsSatz)
                tableZinsen.push(result.zinsen)
                tableForderung.push(gesamtForderung)

                startDate = moment.unix(current.unix())
                currentZins = this.getZinsSatz(current);
            }

            current.add(1, 'day');
        }

        if (! lastDate) {
            lastDate = current
        }

        current.subtract(1, 'day')

        result = this.prozessZins(startDate, current, calcDays, currentZins)

        gesamtForderung += result.zinsen + this.state.forderung.calcValue
        gesamtZinsen += result.zinsen

        tableDates.push(`${result.calcStartDate.format('DD.MM.YYYY')} - ${result.calcEndDate.format('DD.MM.YYYY')}`)
        tableDays.push(result.days)
        tableZinssatz.push(result.zinsSatz)
        tableZinsen.push(result.zinsen)
        tableForderung.push(gesamtForderung)

        const erhoehung = this.prozessZins(current, current, calcDays, currentZins).zinsen

        newState = {
            tagesanzahl,
            tableDates,
            tableDays,
            tableZinssatz,
            tableZinsen,
            tableForderung,
            erhoehung: {
                calcValue: erhoehung,
                styledValue: getStyledValue(erhoehung, 'euro', 2)
            },
            zinsen: {
                calcValue: gesamtZinsen,
                styledValue: getStyledValue(gesamtZinsen, 'euro', 2)
            },
            gesamtforderung: {
                calcValue: gesamtForderung,
                styledValue: getStyledValue(gesamtForderung, 'euro', 2)
            }
        }

        this.setState(newState, () => {
            if (this.calculated === 0) {
                this.calculate()
                this.calculated = 1
            }
            else {
                this.calculated = 0
            }
        })
    }

    handleInput(event, type, decimals) {
        const {name, dataset} = event.target
        let {value} = event.target
        let nullValue = dataset.min || 0

        value = getEditValue(value)

        if (dataset.min && parseFloat(value) < parseInt(dataset.min)) {
            value = dataset.min
        }

        const editValue = getEditValue(value, type) || nullValue.toString()
        const calcValue = getCalcValue(editValue)
        const styledValue = getStyledValue(calcValue, type, decimals)
        const inputValue = getInputValue(value, type)

        let newState = {}

        newState[name] = {
                inputValue,
                calcValue,
                editValue,
                styledValue
        }

        this.setState(newState, () => {
            this.calculate(name)
        })
    }

    handleDateChange(date, name) {
        if (! moment.isMoment(date)) {
            return
        }

        let newState = {}

        const inputValue = date.format('DD.MM.YYYY')
        const calcValue = date.unix()
        const editValue = date.format('DD.MM.YYYY')
        const styledValue = date.format('DD.MM.YYYY')

        newState[name] = {
            inputValue,
            calcValue,
            editValue,
            styledValue,
            moment: date
        }

        this.setState(newState, () => {
            this.calculate(name)
        })
    }

    handleFocus(event) {
        const {name} = event.target

        this.setState(prevState => {
            const {calcValue, editValue, styledValue} = prevState[name]
            const inputValue = editValue

            return {
                [name]: {
                    inputValue,
                    calcValue,
                    editValue,
                    styledValue    
                }
            }
        })
    }

    handleBlur(event) {
        const {name} = event.target

        this.setState(prevState => {
            const {calcValue, editValue, styledValue} = prevState[name]
            const inputValue = styledValue

            return {
                [name]: {
                    inputValue,
                    calcValue,
                    editValue,
                    styledValue    
                }
            }
        })
    }

    handleChange(event) {
        const {name, type, value} = event.target

        switch (type) {
            case 'radio':
                let stateValue = value

                if (name === 'verzugzinsmodell') {
                    stateValue = {
                        value: stateValue,
                        styledValue: this.verzugszinsmodelle[stateValue]
                    }
                }

                this.setState({
                    [name]: stateValue
                }, () => {
                    this.calculate(name)
                })
                break

            default:
                this.setState(prevState => {
                    let newState = {
                        [name]: prevState[name]
                    }
                    
                    if (value === '1') {
                        newState[name].checked = ! prevState[name].checked
                    }
                    else if (value === prevState[name].checked) {
                        newState[name].checked = false
                    }
                    else {
                        newState[name].checked = value
                    }
                
                    return newState

                }, () => {
                    this.calculate(name)
                })
                break
        }

    }

    handleSwitch(event) {
        event.preventDefault()
        event.stopPropagation()
    }

    render() {
        return (
            <CalculatorForm 
                data={this.state}
                handles={this.handles}
            />
        )
    }
}

export default Calculator