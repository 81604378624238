import React from 'react'

import Hamburger from './Hamburger'

class Navigation extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            active: false
        }

        this.handleHamburgerClick = this.handleHamburgerClick.bind(this)
    }

    handleHamburgerClick(event) {
        event.preventDefault()

        this.setState({
            active: ! this.state.active
        })
    }

    render() {
        let menuClasses = ['nav__list']

        if (this.state.active) {
            menuClasses.push('nav__list--active')
        }

        return (
            <>
            <nav className="header__navigation nav">
                <Hamburger clickHandler={this.handleHamburgerClick} active={this.state.active} />
                <ul id="menu" className={menuClasses.join(' ')}>
                    <li className="nav__item"><a href="https://www.roland-prozessfinanz.de/" className="nav__link" target="_blank" rel="noopener noreferrer">Prozessfinanzierung</a></li>
                    <li className="nav__item"><a href="https://www.prozessfinanz-anwälte.de/" className="nav__link" target="_blank" rel="noopener noreferrer">Profil</a></li>
                    <li className="nav__item"><a href="https://www.roland-prozessfinanz.de/blog/" className="nav__link" target="_blank" rel="noopener noreferrer">Blog</a></li>
                </ul>
            </nav>
            </>
        )
    }
}

export default Navigation