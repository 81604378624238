import React from 'react'

import CalculatorBox from '../Box/CalculatorBox'
import CalculatorColumn from '../Column/CalculatorColumn'

import InputField from '../InputFields/InputField'
import Button from '../InputFields/Button'
import DatePicker from '../InputFields/DatePicker'

import 'react-dates/lib/css/_datepicker.css'
import RadioButton from '../InputFields/RadioButton'
import RadioButtonInput from '../InputFields/RadioButtonInput'

const CalculatorSection1 = (props) => {
    const allgemeineAngaben = (
        <>
            <h2>1. Eingabe</h2>
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="forderung"
                label="Forderung"
                labelPosition="outside"
                name="forderung"
                textAlign="left"
                type="euro"
                value={props.data.forderung.inputValue}
            />
        </>
    )

    const startDatum = (
        <>
            <DatePicker 
                date={props.data.startDatum.moment}
                name="startDatum"
                label="Start-Datum"
                labelPosition="outside"
                handleDateChange={props.handles.handleDateChange}
                placeholder="Datum wählen"
            />
        </>
    )

    const endDatum = (
        <>
            <DatePicker 
                date={props.data.endDatum.moment}
                name="endDatum"
                label="End-Datum"
                labelPosition="outside"
                handleDateChange={props.handles.handleDateChange}
                placeholder="Datum wählen"
            />
        </>
    )

    const verbraucherGeschaeftRadio = (
        <RadioButton 
            name="verzugzinsmodell"
            value="verbrauchergeschaeft"
            checked={props.data.verzugzinsmodell.value === 'verbrauchergeschaeft'}
            label="Verbrauchergeschäft"
            handleChange={props.handles.handleChange}
        />
    )

    const handelsGeschaeftRadio = (
        <RadioButton 
            name="verzugzinsmodell"
            value="handelsgeschaeft"
            checked={props.data.verzugzinsmodell.value === 'handelsgeschaeft'}
            label="Handelsgeschäft"
            handleChange={props.handles.handleChange}
        />
    )

    const statischRadio = (
        <RadioButtonInput 
            name="verzugzinsmodell"
            value="statisch"
            checked={props.data.verzugzinsmodell.value === 'statisch'}
            label="Statischer Zinssatz&nbsp;%"
            handleChange={props.handles.handleChange}
            inputName="statischerZinssatz"
            inputDecimals="1"
            inputId="statischerZinssatz"
            handleInput={props.handles.handleInput}
            handleFocus={props.handles.handleFocus}
            handleBlur={props.handles.handleBlur}
            inputType="number"
            inputValue={props.data.statischerZinssatz.inputValue}
        />
    )

    const dynamischRadio = (
        <RadioButtonInput 
            name="verzugzinsmodell"
            value="dynamisch"
            checked={props.data.verzugzinsmodell.value === 'dynamisch'}
            label="Dynamischer Zinssatz&nbsp;%"
            handleChange={props.handles.handleChange}
            inputName="dynamischerZinssatz"
            inputDecimals="1"
            inputId="dynamischerZinssatz"
            handleInput={props.handles.handleInput}
            handleFocus={props.handles.handleFocus}
            handleBlur={props.handles.handleBlur}
            inputType="number"
            inputValue={props.data.dynamischerZinssatz.inputValue}
        />
    )

    if (props.mobile) {
        return (
            <CalculatorBox display="block" paddingBottom={props.data.showGemAngelegenheit ? 50 : 20} stayOnTop={false}>
                <div className="mobile-row">
                    {allgemeineAngaben}
                </div>

                <div className="mobile-row">
                    {startDatum}
                </div>

                <div className="mobile-row">
                  {endDatum}
                </div>

                <div className="mobile-row">
                    <Button name="calc" title="Berechnen"></Button>
                </div>
            </CalculatorBox>
        )
    }

    return (
        <>
            <CalculatorBox alignItems="flex-end" paddingBottom="20" marginBottom="0">
                <CalculatorColumn>
                    {allgemeineAngaben}
                </CalculatorColumn>

                <CalculatorColumn>
                    {startDatum}
                </CalculatorColumn>

                <CalculatorColumn>
                    {endDatum}
                </CalculatorColumn>                        

                <CalculatorColumn alignSelf="flex-end">
                    <Button name="calc" title="Berechnen"></Button>
                </CalculatorColumn>                        
            </CalculatorBox>        

            <CalculatorBox alignItems="center" paddingTop="0">
                <CalculatorColumn>
                    {verbraucherGeschaeftRadio}
                </CalculatorColumn>

                <CalculatorColumn>
                    {handelsGeschaeftRadio}
                </CalculatorColumn>

                <CalculatorColumn>
                    {statischRadio}
                </CalculatorColumn>                        

                <CalculatorColumn alignSelf="flex-end">
                    {dynamischRadio}
                </CalculatorColumn>                        
            </CalculatorBox>        
        </>
    )
}

export default CalculatorSection1