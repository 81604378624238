import React from 'react'
import CalculatorBox from '../Box/CalculatorBox'

import CalculatorColumn from '../Column/CalculatorColumn'
import OutputFieldFlex from '../OutputFields/OutputFieldFlex'

const CalculatorSection2 = (props) => {
    let verzugzinsmodellOutput = props.data.verzugzinsmodell.styledValue

    if (props.data.verzugzinsmodell.value === 'statisch') {
        verzugzinsmodellOutput += ': ' + props.data.statischerZinssatz.styledValue + ' %'
    }

    if (props.data.verzugzinsmodell.value === 'dynamisch') {
        verzugzinsmodellOutput += ': ' + props.data.dynamischerZinssatz.styledValue + ' %'
    }

    const erhoehungText = props.data.erhoehung.calcValue ? (
        <p style={{marginTop: 20, fontSize: '16px'}}>Ab dem {props.data.endDatum.styledValue} erhöht sich die Forderung um {props.data.erhoehung.styledValue} pro Tag.</p>
    ) : null

    return (
        
        <>
            <CalculatorBox background="lightblue3" marginBottom="0">
                <CalculatorColumn flexBasis="100%">
                    <h2 style={{marginBottom: 0}}>2. Ergebnis</h2>
                </CalculatorColumn>
            </CalculatorBox>

            <CalculatorBox background="lightblue3" border="lightblue" marginBottom="0">
                <CalculatorColumn flexBasis="50%">
                    <OutputFieldFlex
                        label="Zeitraum"
                        value={`${props.data.startDatum.styledValue} - ${props.data.endDatum.styledValue}`}
                    />
                </CalculatorColumn>
                <CalculatorColumn flexBasis="50%">
                    <OutputFieldFlex
                        label="Ausgangsforderung"
                        value={props.data.forderung.styledValue}
                    />
                </CalculatorColumn>
            </CalculatorBox>

            <CalculatorBox background="lightblue3" border="lightblue" marginBottom="0">
                <CalculatorColumn flexBasis="50%">
                    <OutputFieldFlex
                        label="Verzugszinsmodell"
                        value={verzugzinsmodellOutput}
                    />
                </CalculatorColumn>
                <CalculatorColumn flexBasis="50%">
                    <OutputFieldFlex
                        label="Zinsen"
                        value={props.data.zinsen.styledValue}
                    />
                </CalculatorColumn>
            </CalculatorBox>

            <CalculatorBox background="lightblue3" border="lightblue">
                <CalculatorColumn flexBasis="50%">
                    <OutputFieldFlex
                        label="Tageszahl"
                        value={props.data.tagesanzahl.styledValue}
                    />
                </CalculatorColumn>
                <CalculatorColumn flexBasis="50%">
                    <OutputFieldFlex
                        label="Gesamtforderung"
                        value={props.data.gesamtforderung.styledValue}
                    />
                    {erhoehungText}
                </CalculatorColumn>
            </CalculatorBox>
        </>
    )
}

export default CalculatorSection2