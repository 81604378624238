import React from 'react'

const CalculatorColumn = (props) => {
    const style = {
        alignSelf: props.alignSelf,
        flexBasis: props.flexBasis,
        textAlign: props.textAlign
    }
    
    return (
        <div className='calculator-box__column' style={style}>
            {props.children}
        </div>
    )
}

export default CalculatorColumn