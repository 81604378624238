import React from 'react'
import InputField from './InputField'

class RadioButtonInput extends React.Component {
    onClick(event) {
        event.stopPropagation()
    }

    render() {
        let classes = ['form-field', 'form-field--radio', 'form-field--radio-input']
        classes = classes.concat(this.props.classes)

        const style = {
            opacity: this.props.opacity
        }

        return(
            <div className={classes.join(' ')} style={style}>
                <label className="form-field__label form-field__label--radio form-field__radio" onClick={this.onClick}>
                    <input 
                        checked={this.props.checked}
                        className="form-field__native-radio" 
                        data-category={this.props.category}
                        name={this.props.name}
                        value={this.props.value} 
                        onChange={
                            event => {
                                this.props.handleChange(event)
                            }
                        }
                        type="radio" 
                    />
                    <span className="form-field__radio-icon"></span>
                    <span className="form-field__radio-text">{this.props.label}</span>
                    <InputField 
                        form="quad"
                        name={this.props.inputName}
                        decimals={this.props.inputDecimals}
                        id={this.props.inputId}
                        value={this.props.inputValue} 
                        handleInput={this.props.handleInput}
                        handleFocus={this.props.handleFocus}
                        handleBlur={this.props.handleBlur}
                        textAlign="center"
                        type={this.props.inputType}
                        disabled={! this.props.checked}
                    />
                </label>
            </div>
        )
    }
}

export default RadioButtonInput