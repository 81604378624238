import React, { Fragment } from 'react'

import CalculatorBox from '../Box/CalculatorBox'
import CalculatorColumn from '../Column/CalculatorColumn'
import { getStyledValue } from '../../../Helpers/valueHelpers';


const CalculatorSection3 = (props) => {
    const tableDates = props.data.tableDates.map((date, id) => {
        return (
            <Fragment key={id}>
                <div className="output-table__date">{date}</div>
                <div className="output-table__days">{props.data.tableDays[id]}</div>
                <div className="output-table__days">{getStyledValue(props.data.tableZinssatz[id], 'number', 2)}</div>
                <div className="output-table__days">{getStyledValue(props.data.tableZinsen[id], 'euro', 2)}</div>
                <div className="output-table__days">{getStyledValue(props.data.tableForderung[id], 'euro', 2)}</div>
            </Fragment>
        )
    })

    return (
        <CalculatorBox background="white">
            <CalculatorColumn flexBasis="100%">
                <h2>3. Ausgabe</h2>

                <div className="output-table">
                    <div className="output-table__head">Zeitraum</div>
                    <div className="output-table__head">Tageszahl</div>
                    <div className="output-table__head">Zinsen %</div>
                    <div className="output-table__head">Zinsen €</div>
                    <div className="output-table__head">Gesamtforderung</div>
                    {tableDates}
                </div>
            </CalculatorColumn>
        </CalculatorBox>
    )
}

export default CalculatorSection3