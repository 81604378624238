const defaultState = {
    forderung: {
        inputValue: '0,00 €',
        calcValue: 0,
        editValue: 0,
        styledValue: '0,00 €',
    },
    startDatum: {
        inputValue: '',
        calcValue: 0,
        editValue: 0,
        styledValue: '',
        moment: null,
    },
    endDatum: {
        inputValue: '',
        calcValue: 0,
        editValue: 0,
        styledValue: '',
        moment: null,
    },
    verzugzinsmodell: {
        value: 'verbrauchergeschaeft',
        styledValue: 'Verbrauchergeschäft',
    },
    statischerZinssatz: {
        inputValue: '1,3',
        calcValue: 1.3,
        editValue: '1,3',
        styledValue: '1,3',
    },
    dynamischerZinssatz: {
        inputValue: '1,3',
        calcValue: 1.3,
        editValue: '1,3',
        styledValue: '1,3',
    },
    ausgangsforderung: {
        calcValue: 0,
        styledValue: '0,00 €',
    },
    zinsen: {
        calcValue: 0,
        styledValue: '0,00 €',
    },
    gesamtforderung: {
        calcValue: 0,
        styledValue: '0,00 €',
    },
    erhoehung: {
        calcValue: 0,
        styledValue: '0,00 €',
    },
    tagesanzahl: {
        calcValue: 0,
        styledValue: '0',
    },

    tableDates: [],
    tableDays: [],
    tableZinssatz: [],
    tableZinsen: [],
    tableForderung: [],

    mobile: false
}

export default defaultState