import React from 'react'

const OutputFieldFlex = (props) => {
    let style = {
        backgroundColor: props.backgroundColor,
        color: props.color,
        height: props.height,
        margin: props.margin,
        padding: props.padding,
        justifyContent: props.justifyContent
    }

    const boldClass = props.bold ? 'bold' : null
    const label = props.label ? <div className={`output-field__label ${boldClass}`}>{props.label}</div> : null

    const classes = [
        'output-field',
        'output-field--flex',
        boldClass
    ]

    return (
        <>
            <div className={classes.join(' ')} style={style}>
                {label}
                <div className="output-field__value">
                    {props.value}
                </div>
            </div>
        </>
    )
}

export default OutputFieldFlex