import React from 'react'
import logo from '../../img/logo.png'

import Navigation from './Navigation'

const Header = () => {
    return(
        <header className="header inside">
            <div className="header__logo">
                <a href="http://www.roland-prozessfinanz.de/" target="_blank" rel="noopener noreferrer"><img src={logo} className="header__logo-image" alt="Roland Prozessfinanz" /></a>
            </div>
            <Navigation />
        </header>
    )
}

export default Header