import React from 'react'

import moment from 'moment'
import 'moment/locale/de'

import 'react-dates/initialize'
import { SingleDatePicker } from 'react-dates'


class DatePicker extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            focused: false
        }
    }

    render() {
        moment.locale('de')

        let formFieldClass = [
            'form-field'
        ]

        if (this.props.labelPosition === 'inside') {
            formFieldClass.push('label-inside')
        }

        if (this.props.form === 'quad') {
            formFieldClass.push('quad')
        }

        const label = this.props.label ? <span className="form-field__label-text">{this.props.label}</span> : null;        

        return (
            <div className={formFieldClass.join(' ')}>
                <label className="form-field__label">
                    {label}
                    <SingleDatePicker
                        noBorder
                        block
                        hideKeyboardShortcutsPanel
                        showDefaultInputIcon
                        isOutsideRange={() => {
                            return false
                        }}
                        id={this.props.name}
                        date={this.props.date}
                        placeholder={this.props.placeholder}
                        focused={this.state.focused}
                        onDateChange={date => {
                            this.props.handleDateChange(date, this.props.name)
                        }} 
                        onFocusChange={({ focused }) => this.setState({ focused })}
                    />
                </label>
            </div>
        )
    }
}

export default DatePicker

