import React from 'react'

import CalculatorSection1 from './Sections/CalculatorSection1'
import CalculatorSection2 from './Sections/CalculatorSection2'
import CalculatorSection3 from './Sections/CalculatorSection3'
// import CalculatorSection4 from './Sections/CalculatorSection4'
// import CalculatorSection5 from './Sections/CalculatorSection5'

const CalculatorForm = (props) => {
    const dataSection1 = {
        data: {
            forderung: props.data.forderung,
            startDatum: props.data.startDatum,
            endDatum: props.data.endDatum,
            verzugzinsmodell: props.data.verzugzinsmodell,
            statischerZinssatz: props.data.statischerZinssatz,
            dynamischerZinssatz: props.data.dynamischerZinssatz,
        },
        mobile: props.data.mobile,
        handles: props.handles
    }

    const dataSection2 = {
        data: {
            forderung: props.data.forderung,
            startDatum: props.data.startDatum,
            endDatum: props.data.endDatum,
            verzugzinsmodell: props.data.verzugzinsmodell,
            statischerZinssatz: props.data.statischerZinssatz,
            dynamischerZinssatz: props.data.dynamischerZinssatz,
            tagesanzahl: props.data.tagesanzahl,
            zinsen: props.data.zinsen,
            gesamtforderung: props.data.gesamtforderung,
            erhoehung: props.data.erhoehung,
        },
        mobile: props.data.mobile
    }

    const dataSection3 = {
        data: {
            tableDates: props.data.tableDates,
            tableDays: props.data.tableDays,
            tableZinssatz: props.data.tableZinssatz,
            tableZinsen: props.data.tableZinsen,
            tableForderung: props.data.tableForderung,
        },
        mobile: props.data.mobile
    }

    return (
        <>
            <CalculatorSection1 {...dataSection1} />
            <CalculatorSection2 {...dataSection2} />
            <CalculatorSection3 {...dataSection3} />
        </>
    )
}

export default CalculatorForm